import { Box, Stack, Typography } from "@mui/material";
import CustomCounter from "../CustomCounter/CustomCounter";
import Laptop from "../../assets/png/laptop.png";
import React from "react";
const MeetingView = React.forwardRef(
  ({ handleChange, signupdetails, isFullScreen, ...props }, ref) => {
    return (
      <Stack ref={ref} sx={{ my: isFullScreen ? "20px" : "20px" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontFamily: "Heebo",
            fontWeight: "600",
            color: "#4f555d",
            mb: "8px",
          }}
        >
          Meeting room devices
        </Typography>
        <Box display={"flex"}>
          <Box display={"flex"} sx={{ flexDirection: "column" }}>
            <Box
              sx={{
                borderRadius: "13px",
                border: "1px solid #7B83EB",
                mb: "16px",
                px: "18px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={Laptop} alt="" className="image-large" />
            </Box>
            <CustomCounter
              min={0}
              placeholder={0}
              value={signupdetails?.meeting_device}
              onHandleChange={(value) => {
                handleChange("meeting_device", value);
              }}
              {...props}
            />
          </Box>
        </Box>
      </Stack>
    );
  }
);
export default MeetingView;
