import { Box } from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useContext, useState } from "react";
import fetchData from "../../axios/fecthData";
import { createIntent } from "../../axios/serverUrl";
import { AppContext } from "../../store/store";
import CustomButton from "../CustomButton";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const { currency } = useContext(AppContext);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }
    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    const data = await fetchData(
      "post",
      createIntent,
      { token: localStorage.getItem("token") },
      {
        user_uuid: localStorage.getItem("user_id"),
        amount: parseInt(localStorage.getItem("amount")) * 100,
        currency: currency.toLowerCase(),
        // currency: "usd",
      }
    );

    try {
      const { error } = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        clientSecret: data.data.client_secret,
        confirmParams: {
          return_url: "https://frontend.shuttle.eqserver.net/",
          // billing_details: {
          //   line1: signupDetailsAddress.signupdetails.address,
          //   line2: signupDetailsAddress.signupdetails.address,
          //   city: signupDetailsAddress.signupdetails.city,
          //   state: signupDetailsAddress.signupdetails.region,
          //   postal_code: signupDetailsAddress.signupdetails.post_code,
          //   country: "india",
          // },
        },
      });
      if (error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("");
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Box
        sx={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end",
          alignItem: "flex-end",
        }}
      >
        <CustomButton type="submit" disabled={!stripe} btnText={"Pay"} />

        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
      </Box>
    </form>
  );
};

export default CheckoutForm;
