import React from "react";
import { Box, Typography, Modal, IconButton } from "@mui/material";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import Error from "../../assets/png/error.png";
import CustomButton from "../CustomButton";
const CustomModal = ({ open, handleClose, handleNext }) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box
      className="errorPopup"
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: { md: "572px", xs: "300px" },
        bgcolor: "#F0F1FF",
        borderRadius: "34px",
        border: "1px solid #8828FF",
        boxShadow: 24,
        pl: { md: "63px", xs: "23px" },
        pt: { md: "23px", xs: "13px" },
        pb: { md: "61px", xs: "31px" },
        pr: { xs: "23px", md: "0px" },
      }}
    >
      <Box
        className="closeBtn"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          pr: { md: "26px", xs: "0px" },
        }}
      >
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <img src={Error} alt="error" style={{ width: "25px", height: "25px" }} />
      <Typography
        className="heading"
        sx={{
          mt: "22px",
          color: "secondary.main",
          fontSize: { md: "30px", xs: "18px" },
          fontStyle: "normal",
          fontWeight: "700",
          fontFamily: "DM Sans",
          lineHeight: "120%",
        }}
      >
        Sorry, you don't have enough phone numbers!
      </Typography>
      <Typography
        id="modal-modal-description"
        sx={{
          mt: "21px",
          mr: { md: "80px", xs: "10px" },
          color: "text.secondary",
          fontSize: { md: "20px", xs: "15px" },
          fontStyle: "normal",
          fontWeight: "400",
          fontFamily: "Heebo",
          lineHeight: "149.5%",
        }}
      >
        It looks like the number of phone numbers being transferred is lower
        than the total number of users. To utilise Teams Calling, it is
        necessary for each user to have a designated phone number. Please
        purchase additional phone numbers to ensure all users are covered.
      </Typography>
      <Box
        className="addMore-btn"
        display={"flex"}
        justifyContent={"center"}
        sx={{ mt: "23px", ml: "-60px" }}
      >
        <CustomButton
          variant="outlined"
          sx={{
            fontSize: { md: "20px", xs: "12px" },
            fontWeight: "700",
          }}
          btnText={"Add more numbers now"}
          onClick={handleClose}
        />
        {/* <CustomButton
          sx={{
            marginLeft: { md: 2, xs: 1 },
            fontSize: { md: "20px", xs: "12px" },
            fontWeight: "700",
          }}
          variant={"contained"}
          btnText={"Proceed to next stage"}
          onClick={handleNext}
        /> */}
      </Box>
    </Box>
  </Modal>
);

export default CustomModal;
