import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useContext, useState } from "react";
import { ReactComponent as Resize } from "../../assets/svg/resize.svg";
import CustomCounter from "../../components/CustomCounter/CustomCounter";
import CustomSwitch from "../../components/CustomSwitch";
import FlagsCarousel from "../../components/FlagsCarousel";
import InputText from "../../components/InputText/InputText";
import country, { codeMap, planImgMap } from "../../utils/Country";
import { addLocationTextHelper } from "../../utils/Constants";
import { BootstrapInput } from "../InputTextWithDD/PhoneNumberInput";
import ReactCountryFlag from "react-country-flag";
import { AppContext } from "../../store/store";
import SummaryPopup from "../SummaryPopup";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className, arrow: className.arrow }}
  />
))(({ theme }) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    background: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    borderRadius: "10px",
    boxShadow: "0px 5px 15px 0px rgba(0,0,0,0.3)",
    width: "180px",
    textAlign: "center",
    lineHeight: "150%",
    fontSize: 12,
  },
}));
const LocationFullContainer = ({
  isFullScreen,
  setFullScreen,
  signupdetails,
  addNewLocation,
  handleChange,
  handleLocation,
  removeLocation,
}) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [isSelectedCountry, setSelectedCountry] = useState(false);
  const changeActiveItem = (idx) => {
    setActiveItemIndex(idx);
  };

  const { currency, setCurrency } = useContext(AppContext);

  const handleCurrencyChange = (event) => {
    const val = event.target.value;
    setCurrency(val);
  };

  const updateNewLocation = (index) => {
    if (
      signupdetails?.location.length > 1 &&
      index !== signupdetails?.location.length - 1
    ) {
      removeLocation(index);
      changeActiveItem(index);
    } else {
      addNewLocation();
      changeActiveItem(index + 1);
    }
  };

  return (
    <Box
      className="flagSlider"
      display="flex"
      sx={{ height: "100%", flexDirection: { xs: "column", md: "row" } }}
      overflow="hidden"
    >
      <Box sx={{ opacity: 0, height: "0px" }}>
        {
          country.find((item) => item?.code === signupdetails?.country_code)
            ?.flag
        }
      </Box>
      <FlagsCarousel
        setCurrency={setCurrency}
        activeItem={activeItemIndex}
        signupdetails={signupdetails}
        handleChange={handleChange}
        handleLocation={handleLocation}
        isSelectedCountry={isSelectedCountry}
        setSelectedCountry={setSelectedCountry}
      />
      {isSelectedCountry && (
        <Box
          className="flagBox"
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Box
            className="flag-item-wrapper"
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            // marginTop={"32px"}
            sx={{
              cursor: "pointer",
            }}
          >
            <Box
              className={"flag-icon"}
              sx={{ borderRadius: 10, display: "flex" }}
            >
              {
                country.find(
                  (item) => item?.code === signupdetails?.country_code
                )?.flag
              }
            </Box>
            <Typography
              sx={{
                paddingLeft: "10px",
                color: "text.light",
                fontSize: "16px !important",
                fontStyle: "normal",
                fontWeight: "700 !important",
                lineHeight: "normal !important",
                fontFamily: "Heebo",
              }}
            >
              {
                country.find(
                  (item) => item?.code === signupdetails?.country_code
                )?.name
              }
            </Typography>
          </Box>
          <Box className="countryChangeBtn">
            <Typography
              onClick={() => setSelectedCountry(false)}
              sx={{
                border: "1px solid #7B83EB",
                borderRadius: "50px",
                color: "#7B83EB",
                padding: "4px 9px",
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "22px",
              }}
            >
              Change
            </Typography>
          </Box>
        </Box>
      )}
      {isSelectedCountry && (
        <Stack
          sx={{
            overflow: "auto",
            width: { xs: "100%", md: "65%" },
            ml: { xs: "0px", md: "20px" },
            position: "relative",
          }}
        >
          <Box
            className="chooseCurrency"
            sx={{
              position: "absolute",
              top: "3px",
              right: "10px",
              display: "flex",
              alignItems: "center",
              gap: "11px",
            }}
          >
            <Typography
              sx={{
                color: "text.secondary",
                fontSize: "14px !important",
                fontWeight: "400 !important",
                lineHeight: "160% !important",
                fontFamily: "Heebo",
                mb: "0px !important",
              }}
            >
              Choose Currency
            </Typography>
            <Select
              className="select-currency"
              input={<BootstrapInput />}
              value={currency}
              onChange={handleCurrencyChange}
            >
              {country
                .filter((item) => item.code !== "SG" && item.code !== "CA")
                .map((item, idx) => (
                  <MenuItem key={idx} value={item.currency.code}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      gap="10px"
                    >
                      <Typography fontSize="13px">
                        ({item.currency.symbol}) {item.currency.code}
                      </Typography>
                      <ReactCountryFlag svg countryCode={item.code} />
                    </Box>
                  </MenuItem>
                ))}
            </Select>
          </Box>
          {signupdetails?.location.map((item, index) => (
            <Stack
              className="countryBlock"
              key={item.country_code + index}
              sx={{ mt: { xs: "60px", md: "10px" }, mb: "20px" }}
            >
              {signupdetails.country_code !== "" && (
                <Stack>
                  <Box key={item.location_name + index}>
                    <Stack>
                      <Box
                        className="countryName"
                        sx={{
                          mb: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "text.secondary",
                            fontSize: "14px !important",
                            fontWeight: "400 !important",
                            fontStyle: "normal !important",
                            lineHeight: "160% !important",
                            display: "flex",
                            fontFamily: "Heebo",
                            alignItems: "center",
                          }}
                        >
                          Country :
                        </Typography>
                        {!item.country_code ? (
                          <Button
                            color="primary"
                            variant="text"
                            type="button"
                            sx={{
                              ml: "5px",
                              fontStyle: "italic",
                              textTransform: "none",
                              borderRadius: "13px",
                              fontWeight: "600",
                              opacity: activeItemIndex === index ? "0.6" : 1,
                            }}
                            onClick={() => changeActiveItem(index)}
                          >
                            select country
                          </Button>
                        ) : (
                          <Typography
                            sx={{
                              ml: "11px",
                              color: "#7B83EB",
                              fontWeight: "600",
                            }}
                          >
                            {codeMap[item.country_code]}
                          </Typography>
                        )}
                        {/* <Typography sx={{ ml: '11px', color: '#7B83EB', fontWeight: '600' }}>
                                   {codeMap[item.country_code]}
                                </Typography> */}
                      </Box>
                      {/* {activeItemIndex !== index && !item.country_code && (
                                <CustomButton
                                   variant="contained"
                                   className="btn-set-active-item"
                                   type="button"
                                   btnText="Select Country"
                                   onClick={() => changeActiveItem(index)}
                                />
                             )} */}
                      <Box overflow={"hidden"}>
                        <Box
                          display={"flex"}
                          flexWrap={"wrap"}
                          className="numbersBox"
                        >
                          <CustomCounter
                            labelName={"Numbers of users"}
                            placeholder={"0"}
                            min={1}
                            boxStyle={{ mr: "40px" }}
                            tooltipTitle={
                              "How many users do you want to be able to make calls?"
                            }
                            value={item?.number_of_users}
                            onHandleChange={(value) =>
                              handleLocation(index, "number_of_users", value)
                            }
                          />
                          <CustomCounter
                            labelName={"Numbers to port"}
                            placeholder={"0"}
                            boxStyle={{ mr: "40px" }}
                            min={0}
                            value={item?.numbers_to_port}
                            tooltipTitle={
                              "How many of your existing numbers do you want to bring across?"
                            }
                            // numberOfUsers={item?.number_of_users}
                            onHandleChange={(value) =>
                              handleLocation(index, "numbers_to_port", value)
                            }
                          />
                          {(signupdetails?.location[0]?.country_code !==
                            item?.country_code ||
                            index === 0) && (
                            <CustomCounter
                              min={0}
                              labelName={"Additional numbers"}
                              placeholder={"0"}
                              isAddition={true}
                              tooltipTitle={
                                "How many additional numbers do you require?"
                              }
                              stepsOfTen
                              value={item?.additional_numbers}
                              onHandleChange={(value) =>
                                handleLocation(
                                  index,
                                  "additional_numbers",
                                  value
                                )
                              }
                            />
                          )}
                        </Box>
                      </Box>
                      <Box display={"flex"} flexWrap={"wrap"} className="plan">
                        <CustomCounter
                          labelName={"Plan"}
                          isPlans={true}
                          imageTooltip={true}
                          tooltipTitle={
                            <Box width={600}>
                              {planImgMap[item.country_code] ? (
                                <img
                                  className="img-fluid"
                                  src={planImgMap[item.country_code]}
                                  alt={`${item.country_code} plan`}
                                ></img>
                              ) : (
                                <Typography
                                  sx={{
                                    ml: "8px",
                                    color: "#000",
                                    fontWeight: "400",
                                  }}
                                >
                                  Image to be provided!
                                </Typography>
                              )}
                            </Box>
                          }
                          selectedPlans={item.plan}
                          country={item.country_code}
                          onHandleChange={(value) =>
                            handleLocation(index, "plan", value)
                          }
                        />
                      </Box>
                      <Box sx={{ my: "34px" }} className="locationInput">
                        <InputText
                          required
                          autoFocus
                          width={"50%"}
                          label={"Location name"}
                          placeholder={"Insert office location name"}
                          name={"location_name"}
                          id={"location_name_" + index}
                          isRequiredIcon={false}
                          defaultValue={item.location_name}
                          onInput={(event) =>
                            handleLocation(
                              index,
                              "location_name",
                              event.target.value
                            )
                          }
                        />
                      </Box>
                      <Box
                        display={"flex"}
                        sx={{ ml: "5px" }}
                        className="tollFreeSwitch"
                      >
                        <CustomSwitch
                          tooltipTitle={
                            "A toll free number is allows people to call your organisation for free"
                          }
                          name={
                            isFullScreen
                              ? "is_toll_free_required_full" + index
                              : "is_toll_free_required" + index
                          }
                          checked={item?.is_toll_free_required}
                          labelName={"Do you need toll free number/s?"}
                          signupdetails={signupdetails}
                          handleOnChange={() =>
                            handleLocation(
                              index,
                              "is_toll_free_required",
                              !item?.is_toll_free_required
                            )
                          }
                        />
                      </Box>
                      <Box
                        sx={{ my: "20px", ml: "5px" }}
                        className="numbersBox needNumber"
                      >
                        {item?.is_toll_free_required && (
                          <CustomCounter
                            labelName={
                              "How many toll free numbers do you need?"
                            }
                            placeholder={"0"}
                            min={1}
                            isTooltip={false}
                            value={item?.total_toll_free_numbers || 1}
                            onHandleChange={(value) => {
                              handleLocation(
                                index,
                                "total_toll_free_numbers",
                                value
                              );
                              handleLocation(
                                index,
                                "inr_call_flow_set_up",
                                value * 10
                              );
                            }}
                          />
                        )}
                      </Box>
                    </Stack>
                  </Box>

                  <Box
                    className="newLocation"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      ml: "5px",
                    }}
                  >
                    <LightTooltip
                      title={
                        "Do you need to add a secondary region or location to your account?"
                      }
                      placement="top"
                    >
                      <Button
                        onClick={() => {
                          updateNewLocation(index);
                        }}
                        sx={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "6px",
                          padding: "6px 12px",
                          backgroundColor:
                            signupdetails?.location.length > 1 &&
                            index !== signupdetails?.location.length - 1
                              ? "#FFCCCB"
                              : "#E4F2F1",
                          color:
                            signupdetails?.location.length > 1 &&
                            index !== signupdetails?.location.length - 1
                              ? "#8b0000"
                              : "#009688",
                          marginTop: "20px",
                          fontFamily: "Heebo",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "140%",
                          textTransform: "none",
                        }}
                      >
                        {addLocationTextHelper(index, signupdetails?.location)}
                      </Button>
                    </LightTooltip>
                    {!isFullScreen &&
                      index === signupdetails?.location.length - 1 && (
                        <IconButton
                          className="btn-fullscreen"
                          onClick={setFullScreen}
                          sx={{
                            display: { xs: "none", md: "flex" },
                            position: "fixed",
                            bottom: "13vh",
                            right: "50px",
                          }}
                        >
                          <Resize />
                        </IconButton>
                      )}
                  </Box>
                </Stack>
              )}
            </Stack>
          ))}
        </Stack>
      )}
      {isSelectedCountry && (
        <Box
          className="summary-popup"
          sx={{ display: { sx: "flex", md: "none" } }}
        >
          <SummaryPopup isAddons={false} {...signupdetails} isPopup={false} />
        </Box>
      )}
    </Box>
  );
};
export default LocationFullContainer;
