import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import BgImage from "../../assets/jpg/bg.jpg";
import fetchData from "../../axios/fecthData";
import {
  addons,
  location,
  payments,
  signup,
  signupDetailsApi,
} from "../../axios/serverUrl";
import CustomFooter from "../../components/CustomFooter/CustomFooter";
import CustomStepper from "../../components/CustomStepper";
import SummaryPopup from "../../components/SummaryPopup";
import Addons from "../../container/Addons";
import Location from "../../container/Location/Location";
import Started from "../../container/Started";
import Summary from "../../container/Summary/Summary";
import { AppContext } from "../../store/store";
import {
  addonsBody,
  isValidEmail,
  labelList,
  locationBody,
  signupBody,
  signupDetailBody,
  signupNullDetails,
  totalPayment,
} from "../../utils/Constants";
import CheckoutSummary from "../CheckoutSummary";

const Signup = () => {
  const [activeStep, setActiveStep] = useState(
    localStorage.getItem("is_checkout") === "true" ? 3 : 0
  );
  const [isSuccess, setSuccess] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);
  const [isNumberUserErrorModal, setNumberUserErrorModal] = useState(false);
  const { currency } = useContext(AppContext);

  useEffect(() => {
    localStorage.setItem("is_checkout", "false");
  }, []);
  useEffect(() => {
    if (currency !== "AUD") {
      resetAddonsAdditionalData();
    }
    setsignupdetails((prev) => {
      return { ...prev, selected_currency: currency };
    });
  }, [currency]);

  const [phoneNumberDetails, setPhoneNumberDetails] = useState({
    fullNumber: "",
    isValid: false,
  });

  const [signupdetails, setsignupdetails] = useState(
    localStorage?.getItem("fabio_details")
      ? JSON.parse(localStorage?.getItem("fabio_details"))
      : signupNullDetails
  );
  // console.log(signupdetails);
  const navigate = useNavigate();

  const { search } = useLocation();

  const phoneNumberHandler = (details) => {
    setsignupdetails((prev) => {
      return {
        ...prev,
        phone: details.phoneNumber,
        full_phone_number: details.fullNumber,
      };
    });
    setPhoneNumberDetails({
      fullNumber: details.fullNumber,
      isValid: details.isValid,
    });
  };

  const sendPayment = async (temp) => {
    const data = await fetchData(
      "post",
      payments,
      { token: localStorage.getItem("token") },
      {
        user_uuid: localStorage.getItem("user_id"),
        charge_id: temp[0],
        payment_status: temp[2],
        client_secret: temp[1],
      }
    );
    if (data?.data) {
      setActiveStep(4);
      if (temp[2] === "succeeded") {
        setSuccess(true);
      } else {
        setSuccess(false);
      }
      navigate("/");
    }
  };
  if (search.replace("?", "").split("&")) {
    const temp = [];
    search.split("&").map((item) => {
      if (item.split("=")[1]) {
        temp.push(item.split("=")[1]);
      }
      return false;
    });
    if (temp.length > 1) {
      sendPayment(temp);
    }
  }
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      // each 'entry' is a [key, value] tupple
      result[key] = value;
    }
    return result;
  }
  useEffect(() => {
    if (search?.includes("utm")) {
      const urlParams = new URLSearchParams(search?.replace("?", ""));
      const entries = urlParams.entries();
      const params = paramsToObject(entries);
      localStorage.setItem("utm_data", JSON.stringify(params));
    }
  }, [search]);

  const handleNext = () => {
    localStorage.setItem("is_checkout", "false");
    localStorage.removeItem("fabio_details");
    localStorage.setItem("fabio_details", JSON.stringify(signupdetails));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    localStorage.setItem("is_checkout", "false");
    onSubmit(true);
  };

  const handleChange = (key, value) => {
    if (key === "country_code") {
      setsignupdetails((prev) => {
        return { ...prev, [key]: value.code, location_name: value.name };
      });
    } else if (key === "ivr_flow_value") {
      setsignupdetails({
        ...signupdetails,
        [key]: value,
        set_up_value:
          value === "curated"
            ? signupdetails?.selected_plan === "36 months"
              ? "$0.00"
              : signupdetails?.selected_plan === "24 months"
              ? "$247.5"
              : "$495"
            : "$0.00",
      });
    } else if (key === "selected_plan") {
      setsignupdetails({
        ...signupdetails,
        [key]: value,
        set_up_value:
          signupdetails?.ivr_flow_value === "curated"
            ? value === "36 months"
              ? "$0.00"
              : value === "24 months"
              ? "$247.5"
              : "$495"
            : "$0.00",
      });
    } else {
      setsignupdetails({ ...signupdetails, [key]: value });
      if (key === "ivr_flow" && value === false) {
        setsignupdetails((prev) => {
          return { ...prev, total_ivr_flow: 0 };
        });
      }
    }
  };
  const removeLocation = (index) => {
    setsignupdetails({
      ...signupdetails,
      location: signupdetails?.location?.filter(
        (val, index1) => index1 !== index + 1
      ),
    });
  };

  const handleLocation = (index, key, value) => {
    if (key === "is_selected_country") {
      setsignupdetails((prev) => {
        return {
          ...prev,
          location: prev?.location?.map((val, index1) =>
            index1 === index ? { ...val, country_code: value.code } : val
          ),
        };
      });
      if (value.code !== "AU") {
        resetAddonsAdditionalData();
      }
    }
    // if toll free numbers are not required set toll free quantity to zero
    else if (key === "is_toll_free_required" && value === false) {
      setsignupdetails((prev) => {
        const newLocation = prev?.location?.map((val, index1) =>
          index1 === index
            ? { ...val, [key]: value, total_toll_free_numbers: 1 }
            : val
        );
        return { ...prev, location: newLocation };
      });
    } else {
      setsignupdetails((prev) => {
        const newLocation = prev?.location?.map((val, index1) =>
          index1 === index ? { ...val, [key]: value } : val
        );
        return { ...prev, location: newLocation };
      });
    }
  };

  const addNewLocation = () => {
    setsignupdetails({
      ...signupdetails,
      location: [
        ...signupdetails.location,
        {
          country_code: "",
          location_name: "",
          user_id: 0,
          number_of_users: 1,
          numbers_to_port: 0,
          additional_numbers: 0,
          plan: {
            id: 1,
            name: "Starter",
            price: "36",
          },
          is_toll_free_required: false,
          total_toll_free_numbers: 1,
          is_primary: true,
        },
      ],
    });
    resetAddonsAdditionalData();
  };

  const onSubmit = async (isBack = false) => {
    // console.log(signupdetails);
    // return handleNext();
    if (
      !signupdetails.email ||
      !signupdetails.first_name ||
      !signupdetails.last_name ||
      !signupdetails.phone ||
      !signupdetails.company_name
    ) {
      toast("All fields required!");
    } else {
      if (activeStep === 0) {
        if (isValidEmail(signupdetails?.email)) {
          if (phoneNumberDetails.isValid) {
            sendApi(signup, signupBody(signupdetails), activeStep, isBack);
          } else {
            toast("Please enter a valid phone number!");
          }
        } else {
          toast("Email is invalid!");
        }
      } else if (activeStep === 1) {
        if (isBack) {
          sendApi(
            location,
            locationBody(signupdetails, activeStep),
            activeStep,
            isBack
          );
        } else {
          let isNextStep = true;
          if (!isNumberUserErrorModal) {
            signupdetails.location.forEach((element) => {
              if (!isNextStep) return;

              if (
                element.additional_numbers + element.numbers_to_port <
                element.number_of_users
              ) {
                setNumberUserErrorModal(true);
                isNextStep = false;
              } else {
                isNextStep = true;
              }
            });
          }
          if (isNextStep && !isBack) {
            sendApi(location, locationBody(signupdetails), activeStep, isBack);
          }
        }
      } else if (activeStep === 2) {
        sendApi(addons, addonsBody(signupdetails), activeStep, isBack);
      } else if (activeStep === 3) {
        if (isBack) {
          const totalAmount = totalPayment(signupdetails, activeStep);
          localStorage.setItem("amount", totalAmount);
          localStorage.setItem("currency", currency);
          const utm_data = localStorage.getItem("utm_data");
          const body = signupDetailBody(signupdetails, utm_data);
          localStorage.setItem("fabio_details_address", JSON.stringify(body));
          sendApi(signupDetailsApi, body, activeStep, isBack);
        } else {
          if (
            !signupdetails.business_reg_no ||
            !signupdetails.address ||
            !signupdetails.city ||
            !signupdetails.post_code ||
            !signupdetails.region
          ) {
            toast("All fields required!");
          } else if (
            signupdetails.shipping === "Multiple Addresses" &&
            (!signupdetails.shipping_address ||
              !signupdetails.shipping_city ||
              !signupdetails.shipping_post_code ||
              !signupdetails.shipping_region)
          ) {
            toast("All fields required!");
          } else {
            const totalAmount = totalPayment(signupdetails, activeStep);
            localStorage.setItem("amount", totalAmount);
            localStorage.setItem("currency", currency);
            const utm_data = localStorage.getItem("utm_data");
            const body = signupDetailBody(signupdetails, utm_data);
            localStorage.setItem("fabio_details_address", JSON.stringify(body));
            sendApi(signupDetailsApi, body, activeStep, isBack);
          }
        }
      }
    }
  };

  const sendApi = async (endpoint, body, activeStep, isBack = false) => {
    const data = await fetchData(
      "post",
      endpoint,
      { token: localStorage.getItem("token") },
      body
    );

    if (data.data) {
      if (isBack) {
        if (activeStep === 0) {
          localStorage.setItem("user_id", data.data.uuid);
          localStorage.setItem("token", data.data.token);
        }
        if (activeStep === 3) {
          localStorage.removeItem("fabio_details");
          localStorage.setItem("fabio_details", JSON.stringify(signupdetails));
        }

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      } else {
        if (activeStep === 0) {
          localStorage.setItem("user_id", data.data.uuid);
          localStorage.setItem("token", data.data.token);
        }
        if (activeStep === 3) {
          localStorage.removeItem("fabio_details");
          localStorage.setItem("fabio_details", JSON.stringify(signupdetails));
          navigate("/checkout");
        } else {
          handleNext();
        }
      }
    } else {
      toast(data.message);
    }
  };

  const [isTransitionActive, setIsTransitionActive] = useState(false);

  const resetAddonsAdditionalData = () => {
    setsignupdetails((prev) => {
      return {
        ...prev,
        basic_call_recording: 0,
        basic_call_user: 0,
        premium_call_recording: 0,
        premium_call_user: 0,
        handset: 0,
        headset1: 0,
        headset2: 0,
        headset3: 0,
        meeting_device: 0,
      };
    });
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        className={"main"}
        style={{
          backgroundPositionY: "center",
          backgroundPositionX: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${BgImage})`,
        }}
        sx={{
          pl: { xs: "12px", md: "60px" },
          pr: { xs: "12px", md: "69px" },
          pb: { xs: "10px", md: "0px" },
          // py: { xs: "10px", md: 5 },
          // minHeight: '100vh',
          height: { xs: "auto", md: "100vh" },
        }}
      >
        <Box
          display="flex"
          sx={{ flexDirection: { md: "row", xs: "column" } }}
          width={"100%"}
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box
            width={{ md: "25%", xs: "100%" }}
            sx={{ display: { md: "flex", xs: "flex" } }}
            justifyContent={"start"}
            alignItems={"center"}
          >
            <CustomStepper activeStep={activeStep} />
          </Box>
          <Box
            display={"flex"}
            width={"75%"}
            height={"89%"}
            sx={{ width: { md: "75%", xs: "100%" } }}
          >
            <Box
              component="form"
              noValidate
              autoComplete="off"
              className="details-form-container"
              width={"100%"}
              style={{
                backgroundColor: "#EBF6FF",
                borderRadius: 20,
                backdropFilter: "blur(137px)",
              }}
              sx={{
                pl: { md: "40px", xs: "20px" },
                pr: { md: "40px", xs: "10px" },
                pt: { md: "40px", xs: "30px" },
                pb: { md: "40px", xs: "30px" },
                overflow: { md: "hidden", xs: "auto" },
              }}
            >
              {/* <form style={{ width: "100%", height: "100%" }}> */}
              <Typography
                variant="h4"
                color="secondary"
                sx={{
                  fontSize: { md: "55px", sx: "25px" },
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "700",
                }}
              >
                {labelList[activeStep || 0]?.label}
              </Typography>
              <Box
                className="details-form-wrapper"
                sx={{
                  mt: {
                    xs: "30px",
                    md: activeStep !== 2 && activeStep !== 1 ? "30px" : "10px",
                  },
                  overflow: isTransitionActive ? "hidden" : "auto",
                  height: {
                    lg:
                      activeStep === 1
                        ? "80% !important"
                        : activeStep === 3
                        ? "76% !important"
                        : "79%",
                    md:
                      activeStep !== 2
                        ? activeStep === 0
                          ? "70%"
                          : "80%"
                        : "80%",
                  },
                }}
              >
                <CSSTransition
                  onEnter={() => setIsTransitionActive(true)}
                  onEntered={() => setIsTransitionActive(false)}
                  timeout={400}
                  classNames="step-container"
                  unmountOnExit
                  in={activeStep === 0}
                >
                  <Started
                    onPhoneNumberChange={phoneNumberHandler}
                    handleChange={handleChange}
                    signupdetails={signupdetails}
                  />
                </CSSTransition>
                <CSSTransition
                  onEnter={() => setIsTransitionActive(true)}
                  onEntered={() => setIsTransitionActive(false)}
                  timeout={400}
                  classNames="step-container"
                  unmountOnExit
                  in={activeStep === 1}
                >
                  <Location
                    isNumberUserErrorModal={isNumberUserErrorModal}
                    handleModalClose={() => setNumberUserErrorModal(false)}
                    setFullScreen={() => setFullScreen(!isFullScreen)}
                    isFullScreen={isFullScreen}
                    handleLocation={handleLocation}
                    handleChange={handleChange}
                    removeLocation={removeLocation}
                    addNewLocation={addNewLocation}
                    signupdetails={signupdetails}
                    handleNext={() => {
                      setNumberUserErrorModal(false);
                      sendApi(
                        location,
                        locationBody(signupdetails, activeStep),
                        activeStep,
                        false
                      );
                    }}
                  />
                </CSSTransition>
                <CSSTransition
                  onEnter={() => setIsTransitionActive(true)}
                  onEntered={() => setIsTransitionActive(false)}
                  timeout={400}
                  classNames="step-container"
                  unmountOnExit
                  in={activeStep === 2}
                >
                  <Addons
                    handleChange={handleChange}
                    setFullScreen={() => setFullScreen(!isFullScreen)}
                    isFullScreen={isFullScreen}
                    signupdetails={signupdetails}
                  />
                </CSSTransition>
                <CSSTransition
                  onEnter={() => setIsTransitionActive(true)}
                  onEntered={() => setIsTransitionActive(false)}
                  timeout={400}
                  classNames="step-container"
                  unmountOnExit
                  in={activeStep === 3}
                >
                  <Summary
                    handleChange={handleChange}
                    signupdetails={signupdetails}
                  />
                </CSSTransition>
                <CSSTransition
                  onEnter={() => setIsTransitionActive(true)}
                  onEntered={() => setIsTransitionActive(false)}
                  timeout={400}
                  classNames="step-container"
                  unmountOnExit
                  in={activeStep === 4}
                >
                  <CheckoutSummary isSuccess={isSuccess} />
                </CSSTransition>
              </Box>
              {activeStep !== 4 && (
                <CustomFooter
                  activeStep={activeStep}
                  handleChange={handleChange}
                  handleBack={handleBack}
                  signupdetails={signupdetails}
                  onSubmit={() => onSubmit(false)}
                />
              )}
              {/* </form> */}
            </Box>
          </Box>
        </Box>
        {((activeStep === 1 && signupdetails?.country_code !== "") ||
          activeStep === 2 ||
          activeStep === 3) && (
          <Box
            className="summary-popup"
            sx={{
              display: { xs: "none", md: "flex" },
              position: "absolute",
              transform: "translateY(-50%)",
              top: activeStep === 1 ? "407px" : "45%",
              right: { xs: "0", md: "12px" },
            }}
          >
            <SummaryPopup
              isAddons={activeStep === 2 ? true : false}
              {...signupdetails}
            />
          </Box>
        )}
      </Box>
      <Toaster
        position={"top-right"}
        toastOptions={{
          style: {
            background: "#ee2400",
            color: "#fff",
          },
        }}
      />
    </React.Fragment>
  );
};
export default Signup;
