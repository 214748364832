import { Step, Box, StepLabel, Stepper, useMediaQuery } from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { useTheme } from "@emotion/react";

const steps = [
  {
    label: "Get Started",
  },
  {
    label: "Location",
  },
  {
    label: "Addons",
  },
  {
    label: "Enquiry Summary",
  },
];
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));
const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 59,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#7b83eb",
  }),
}));

const QontoStepIcon = (props) => {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div
          className="stepIcon"
          style={{
            width: "56px",
            height: "56px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ebf6ff",
            borderRadius: "50%",
            borderColor: "#7b83eb",
            border: "1px solid #7b83eb",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="22"
            viewBox="0 0 30 22"
            fill="none"
          >
            <path
              d="M2 13.0506L9.37457 20.4252L27.811 1.98877"
              stroke="#7B83EB"
              strokeWidth="2.76546"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ) : active ? (
        <div
          className="stepIcon"
          style={{
            width: "56px",
            height: "56px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#7B83EB",
            borderRadius: "50%",
            borderColor: "#7b83eb",
            border: "1px solid #7b83eb",
          }}
        />
      ) : (
        <div
          className="stepIcon"
          style={{
            width: "56px",
            height: "56px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#EBF6FF",
            borderRadius: "50%",
            borderColor: "#7b83eb",
            border: "1px solid #7b83eb",
          }}
        />
      )}
    </QontoStepIconRoot>
  );
};

const CustomStepper = ({ activeStep, boxWidth }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      width={boxWidth}
      display={"flex"}
      justifyContent={"start"}
      alignItems={"center"}
      className={"stepBox"}
    >
      <Stepper
        activeStep={activeStep}
        orientation={isMobile ? "horizontal" : "vertical"}
      >
        {steps.map((step) => (
          <Step key={step.label} connector={<QontoConnector />}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              sx={{
                color: "text.primary",
                fontSize: "20px !important",
                fontWeight: "700 !important",
                fontFamily: "DM Sans !important ",
                fontStyle: "normal",
              }}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
export default CustomStepper;
