import { Box, Stack, Typography } from "@mui/material";
import CustomCounter from "../CustomCounter/CustomCounter";
import Headset1 from "../../assets/png/headset1.png";
import Headset2 from "../../assets/png/headset2.png";
import Headset3 from "../../assets/png/headset3.png";
import Handset from "../../assets/png/handset.png";
import React from "react";

const ImageWithCounter = React.forwardRef(({ handleChange, signupdetails, isFullScreen }, ref) => {
  return (
    <Stack ref={ref} sx={{my: isFullScreen ? '20px' : '0'}}>
      <Typography sx={{fontSize: '16px', fontFamily: 'Heebo',fontWeight: '600', color: '#4f555d', mb: '8px'}}>
        Headsets & Handsets
      </Typography>
      <Typography
        className="blockItem"
        sx={{
          color: "#5E718B",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "700",
          fontFamily: "Heebo",
          lineHeight: "160%",
        }}
      >
        Headsets
      </Typography>
      <Box display={"flex"} sx={{ mb: "20px" }} flexWrap={"wrap"} className="productBox">
        <Box sx={{ mr: "21px", mt: "9px" }}>
          <Box
            sx={{
              borderRadius: "13px",
              border: "1px solid #7B83EB",
              mb: "16px",
              px: "18px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={Headset1} alt="" className="image-large" />
          </Box>
          <CustomCounter
            placeholder={0}
            min={0}
            value={signupdetails?.headset1}
            onHandleChange={(value) => {
              handleChange("headset1", value);
            }}
          />
        </Box>
        <Box sx={{ mr: "21px", mt: "9px" }}>
          <Box
            sx={{
              borderRadius: "13px",
              border: "1px solid #7B83EB",
              px: "18px",
              mb: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={Headset2} alt="" className="image-large" />
          </Box>
          <CustomCounter
            placeholder={0}
            min={0}
            value={signupdetails?.headset2}
            onHandleChange={(value) => {
              handleChange("headset2", value);
            }}
          />
        </Box>
        <Box sx={{ mr: "21px", mt: "9px" }}>
          <Box
            sx={{
              borderRadius: "13px",
              border: "1px solid #7B83EB",
              px: "18px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: "16px",
            }}
          >
            <img src={Headset3} alt="" className="image-large" />
          </Box>
          <CustomCounter
            placeholder={0}
            min={0}
            value={signupdetails?.headset3}
            onHandleChange={(value) => {
              handleChange("headset3", value);
            }}
          />
        </Box>
      </Box>
      <Typography
        className="blockItem"
        sx={{
          color: "#5E718B",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "160%",
          fontFamily: "Heebo",
        }}
      >
        Handsets
      </Typography>
      <Box display={"flex"} sx={{ mt: "9px" }} className="productBox">
        <Box>
          <Box
            sx={{
              borderRadius: "13px",
              border: "1px solid #7B83EB",
              px: "18px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: "16px",
            }}
          >
            <img src={Handset} alt="" className="image-large" />
          </Box>
          <CustomCounter
            placeholder={0}
            min={0}
            value={signupdetails?.handset}
            onHandleChange={(value) => {
              handleChange("handset", value);
            }}
          />
        </Box>
      </Box>
    </Stack>
  );
});
export default ImageWithCounter;
